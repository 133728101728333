import { version } from "$app/environment"
import { PUBLIC_ENV } from "$env/static/public"
import {
    handleErrorWithSentry,
    replayIntegration,
    httpContextIntegration,
    httpClientIntegration,
} from "@sentry/sveltekit"
import * as Sentry from "@sentry/sveltekit"

Sentry.init({
    dsn: "https://e911bde77fb8f2cf0c0c863c5c809348@o4507685475123200.ingest.de.sentry.io/4508285333602384",

    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [replayIntegration(), httpContextIntegration(), httpClientIntegration()],
    environment: PUBLIC_ENV,
    release: version,
    enabled: PUBLIC_ENV !== "local" && PUBLIC_ENV !== "preview",
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
